/* xs */
.hero-section {
    padding: 6rem 0 6rem 0;
}
.button-col {
    margin: 4rem 0 0 0;
}

/* sm */
@media (min-width: 576px) {
    .hero-section {
        padding: 5rem 0 7rem 0;
    }
    .button-col {
        margin: 5rem 0 0 0;
    }
}

/* md */
@media (min-width: 768px) {
    .hero-section {
        padding: 5rem 0 7rem 0;
    }
    .button-col {
        margin: 5rem 0 0 0;
    }
}

/* lg */
@media (min-width: 992px) {
    .hero-section {
        padding: 6rem 0 7rem 0;
    }
    .button-col {
        margin: 4rem 0 0 0;
    }
}

/* xl */
@media (min-width: 1200px) {
    .hero-section {
        padding: 7rem 0 7rem 0;
    }
    .button-col {
        margin: 6rem 0 0 0;
    }
}

/* xxl */
@media (min-width: 1400px) {
    .hero-section {
        padding: 8rem 0 7rem 0;
    }
    .button-col {
        margin: 8rem 0 0 0;
    }
}
